// ######################################
// --------------------------------------
// ########### THEME COLORS #############
// --------------------------------------
// XX DO NOT CHANGE NAMING CONVENTIONS XX
// ######################################
$black-color: #000000;
$white-color: #ffffff;
$primary-color:  #e87204;
$secondary-color: #e87204;
$primary-light: #ffee32;
$primary-light1: #90a955;
$primary-transparent: #dc5f5f08;
$primary-transparent1: #23194208;
$primary-transparent2: #23194250;
$navlink-color: #e87204;
$gradient1: #e87204;
$gradient2: #1d1411;

// #######################################
// EXTRA COLORS ##########################
// #######################################
$light-blue: rgb(71, 118, 235);
$theme-blue-dark: #26b263;
$scrollbar-bg: #d35400;
$card-purple-bg: #415d431e;
$orange: #f9c74f;
$theme-green: #00bf78;
$theme-green-transparent: #a71c0a08;
$light-green1: #00bf79b3;
$transparent-green: #00bf7931;
$dark-gray: #314259;
$grey: #f8fbff;
$off-white: #e6e6e6;
$theme-blue: #57aaee;
$pink-color: #ff69b4;
$red-color: #d41d1d;
$grey1: #cdced4;
$light-green: #f2fdfa;
$dark-gray: #314259;
$transparent-red: #dc5f5f29;
$divider-color: #9e9c9c;
$weDo-bg: #f2f2f2;
$pin-bg: #dcdaff;
$light-gray: rgb(202, 202, 202);
$icon-blue: #0465e4;

// 231942
//  5e548e
// 9f86c0
// be95c4
// e0b1cb
